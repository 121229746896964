import {Button, Modal, Radio, Select, Space} from "antd";
import React from "react";

const AdsModal = (
    {modalType,
        setModal,
        modal,
        setPromoteId,
        handleModalAction,
        setReason,
        adId,
        reason,
        promoteId
    }) => {

    return (
        <Modal
            centered
            title={modalType === "promote" ? "ارتقا آگهی" :
                modalType === "delete" ? "آیا از حذف این آگهی مطمئن هستی؟" :
                    null}
            open={modal}
            onCancel={()=>setModal(false)}
            footer={[]}
        >
            {modalType === "promote" ?
                <div className={"pt-3"}>
                    <Radio.Group >
                        <Space direction="vertical">
                            <Radio value="2" onChange={(e)=>setPromoteId(parseInt(e.target.value))}>
                                <span className={'SpecialAdsFast  mx-2'}>فوری</span><span><strong>25.000</strong> تومان</span>
                                <p className={'mt-2 promote-text'}>آگهی شما به مدت سه روز با برچسب فوری نمایش داده خواهد شد.</p>
                            </Radio>
                            <Radio value="1" onChange={(e)=>setPromoteId(parseInt(e.target.value))}>
                                <span className={'SpecialAds mx-2'}>ویترین</span><span><strong>50.000</strong> تومان</span>
                                <p className={'mt-2 promote-text'}>آگهی شما به مدت یک ماه با برچسب ویترین و در دسته بندی خود در بالای همه آگهی ها نمایش داده خواهد شد همچنین می تونید آگهی خود رو بدون محدودیت بروز کنید. همچنین آگهی شما در شبکه های اجتماعی منتشر خواهد شد.</p>
                            </Radio>
                            <Radio value="3" onChange={(e)=>setPromoteId(parseInt(e.target.value))}>
                                <span className={'LinkedAds mx-2'}>لینک وب سایت</span><span><strong>100.000</strong> تومان</span>
                                <p className={'mt-2 promote-text'}>نمایش لینک وب سایت شما در آگهی بصورت نوفالو. بعد از پرداخت می تونید در ویرایش آگهی آدرس وب سایت تون رو اضافه کنید.</p>
                            </Radio>
                        </Space>
                    </Radio.Group>

                    <Button onClick={()=>handleModalAction('promote', adId)} type="primary" disabled={!promoteId}>پرداخت</Button>
                </div>
                : modalType === "delete" ?
                    <>
                        <p>با حذف این آگهی تمامی اطلاعات و تصاویر حذف خواهد شد! دلیل حذف آگهی چیه؟</p>
                        <Select
                            placeholder={"انتخاب کنید"}
                            className={"w-100"}
                            onChange={(choice)=>setReason(choice)}
                            options={[
                                {label: 'فروخته شد!', value: 1},
                                {label: 'پشیمون شدم بفروشم!', value: 2},
                                {label: 'نمیخرن!', value: 3},
                                {label: 'آگهی رو اشتباه زدم!', value: 4},
                                {label: 'غیره', value: 5}
                            ]}/>
                        <Button
                            onClick={()=> handleModalAction('delete', adId)}
                            type="primary" danger disabled={!reason} className={"mt-4"}>حذف</Button>
                    </>
                    : null}
        </Modal>
    )
}

export default AdsModal;