const Scripts = {
    urlDasher (str){
        return str ? str.replace(/\s+/g,'-') : null;
    },
    formatPrice (p){
        return p?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    validateURL (url) {
        const urlPattern = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,6}\.?)(\/[\w.]*)*\/?$/i;
        return urlPattern.test(url);
    },
    slugGenerator (str){
        return str
            ? str
                .trim()
                .toLowerCase()
                .replace(/[^a-zA-Z0-9\u0600-\u06FF\s-]/g, '')
                .replace(/\s+/g, '-')
                .replace(/-+/g, '-')
            : null;
    }
}

export default Scripts;