import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    getSellerAdsApi,
    getSellerAdsRefresh,
    deleteAdsByIdApi,
    postPromoteAdsApi
} from "../../api/api";
import SellerLayout from "../../layout/seller";
import {
    Alert, Button,
    Col,
    notification,
    Pagination,
    Row,
    Spin
} from "antd";
import SellerAds from "../../components/seller/ads";
import AdsStyle from "../../assets/css/ads.module.css";
import AdsTabs from "../../layout/seller/components/adsTabs";
import AdsModal from "../../components/seller/modal";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {

    const [Ads, setAds] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [reason, setReason] = useState(0);
    const [promoteId, setPromoteId] = useState(null);
    const token = useSelector((state) => state.auth.token);
    const userState = useSelector((state)=> state.auth?.state?.ads) || null;
    const [activeTab, setActiveTab] = useState(null);
    const [hasAds, setHasAds] = useState(false);
    const navigate = useNavigate();
    const [adId, setAdId] = useState(null);

    useEffect(()=>{
        document.title = "نوبازار - داشبورد"
    },[]);

    useEffect(()=>{
        getSellerAds();
    },[activeTab]);

    const getSellerAds  = async(p) => {
        setProcessing(true)
        const data = {
            page: p ? p : 1,
            ...(activeTab != null && activeTab >= 0 && { status: activeTab }),
            paginate: true
        }
        await getSellerAdsApi(data, token).then((ads) => {
            setAds(ads.data);
            if(ads.data.data.length){
                setHasAds(true);
            } else {
                setHasAds(false);
            }

            setTimeout(()=>{
                setProcessing(false)
            },1000);

        }).catch((error)=>{
            console.log(error)
        })
    }

    const handlePage = (p) => {
        getSellerAds(p)
    }

    const getActiveTab = (x) => {
        setActiveTab(x)
    }

    const handleRefresh = async (id) => {
        await getSellerAdsRefresh(id, token).then(()=>{
            getSellerAds()
            notification.success({
                description: "آگهی با موفقیت بروزرسانی شد",
                placement: "bottomRight"
            })
        }).catch(()=>{
            notification.error({
                message: "بروزرسانی انجام نشد",
                description: "شما هر 72 ساعت یکبار مجاز به بروزرسانی می باشید",
                placement: "bottomRight"
            })
        })
    }

    const handleModalAction = async(w, id) => {
        if(w === 'delete'){
            const data = {
                reason: reason
            }
            await deleteAdsByIdApi(id, data, token).then(()=>{
                getSellerAds(1).then();
                setModal(false);
                notification.success({
                    description: "آگهی با موفقیت حذف شد",
                    placement: "bottomRight"
                })
            });
        } else if(w === 'promote'){
            let price = 0;
            if(promoteId === 2){
                price = 25000;
            } else if(promoteId === 1) {
                price = 50000;
            } else if(promoteId === 3) {
                price = 100000;
            }
            const data = {
                amount: price,
                ads_id: id,
                type: promoteId,
                description: `ارتقا آگهی ${id}`
            }
            await postPromoteAdsApi(data, token).then((promote)=>{
                window.location.href = "https://zarinpal.com/pg/StartPay/" + promote.data.authority
            });
        }
    }

    const handleDelete = (id) => {
        setModal(true);
        setModalType('delete');
        setAdId(id);
    }

    const handlePromote = async(id) => {
        setModal(true);
        setModalType('promote');
        setAdId(id);
    }

    return (
        <SellerLayout>
            <div className={AdsStyle.AdsWrapper}>
                <div className="d-flex align-items-center justify-content-between">
                    <h1 style={{fontSize: "20px"}}>آگهی های من</h1>
                    <Button type="primary" onClick={()=>navigate("/ads/new")}>درج آگهی جدید</Button>
                </div>
                <AdsTabs userState={userState} getActiveTab={getActiveTab}/>
                <Row gutter={[16, 16]}>
                    {hasAds ? Ads?.data.map(function (Ad, Index){
                            return (
                                <Col key={Index} xxl={8} xl={12} lg={12} md={24} xs={24}>
                                    <SellerAds
                                        handlePromote={()=>handlePromote(Ad.id)}
                                        handleDelete={()=>handleDelete(Ad.id)}
                                        handleRefresh={()=>handleRefresh(Ad.id)}
                                        loading={processing}
                                        Ad={Ad}
                                    />
                                </Col>
                            )
                        }) :
                        processing ?
                            <Col span={24}>
                                <div style={{padding: "15px 0"}} className="d-flex align-items-center justify-content-center">
                                    <Spin />
                                </div>
                            </Col> :
                            <Col span={24}>
                                <Alert
                                    message={<strong>کاربر گرامی</strong>}
                                    description="هیچ آگهی ثبت نشده است"
                                    type="warning"
                                    showIcon
                                />
                            </Col>}
                </Row>
            </div>

            {Ads?.total > Ads?.per_page ?
                <div style={{marginTop: "25px"}} className="d-flex align-items-center justify-content-center">
                    <Pagination
                        total={Ads?.total}
                        perPage={Ads?.per_page}
                        currentPage={Ads?.current_page}
                        onChange={(e) => {handlePage(e)}}
                    />
                </div>
                : null}

            <AdsModal
                modalType={modalType}
                setModal={setModal}
                modal={modal}
                setPromoteId={setPromoteId}
                handleModalAction={handleModalAction}
                setReason={setReason}
                adId={adId}
                reason={reason}
                promoteId={promoteId}
            />
        </SellerLayout>
    )
}

export default Dashboard;